import './App.css';
import React from "react";
import {v4 as uuidv4} from "uuid";
import QrCode from "qrcode.react"
import {QrReader} from 'react-qr-reader';
import Countdown from "react-countdown";

const storage_key = "com.triviamaze.sessionid";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            last_message: null,
            questions: "",
            game: "",
            name: "",
            team: "",
            difficulty: "",
            questions_array: [],
            answers_array: [],
            show_qr_scanner: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeNoReplace = this.handleChangeNoReplace.bind(this);
    }

    send(m) {
        let s = JSON.stringify(m)
        window.triviamaze_socket.send(s);

    }

    join() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "Join"
            }
        });
    }

    move_left() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "Left"
            }
        });
    }

    move_right() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "Right"
            }
        });
    }

    move_up() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "Up"
            }
        });
    }

    move_down() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "Down"
            }
        });
    }


    start_game() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "StartGame"
            }
        });
    }

    newGameFromQuestionAnswers() {
        let a = this.state.answers_array;
        let q = this.state.questions_array;
        let questions = "";
        for (let i = 0; i < a.length; ++i) {
            if (a[i].trim().length > 0 && q[i].trim().length > 0) {
                questions += q[i].trim();
                questions += "\n";
                questions += a[i].trim();
                questions += "\n";
            }

        }
        if (questions.trim().length > 0) {

            this.send({
                id: window.triviamaze_session,
                cmd: {
                    type: "NewGame",
                    questions: questions
                }
            });
        }
    }

    new_built_in_game(quiz) {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "NewGame",
                questions: quiz
            }
        });
    }

    new_game() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "NewGame",
                questions: this.state.questions
            }
        });
    }

    set_game() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "SetGame",
                game: this.state.game,
            }
        });
    }

    next_question() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "NextQuestion",
            }
        });
    }

    set_auto_advance_question(value) {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "SetAutoAdvanceQuestion",
                value: value
            }
        });
    }


    add_observer() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "AddObserver",
                id: this.state.tvid,
            }
        });
    }


    setName() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "SetName",
                name: this.state.name,
                team: null,
                difficulty: null
            }
        });
    }

    setTeam() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "SetName",
                name: null,
                team: this.state.team,
                difficulty: null
            }
        });
    }

    setDifficulty() {
        this.send({
            id: window.triviamaze_session,
            cmd: {
                type: "SetName",
                name: null,
                team: null,
                difficulty: this.state.difficulty
            }
        });
    }


    reset_session() {
        window.sessionStorage.setItem(storage_key, uuidv4())
        window.triviamaze_session = window.sessionStorage.getItem(storage_key);
        this.join();
    }

    componentDidMount() {
        let app = this;
        if (!window.sessionStorage.getItem(storage_key)) {
            window.sessionStorage.setItem(storage_key, uuidv4())
        }
        window.triviamaze_session = window.sessionStorage.getItem(storage_key);
        let protocol = window.location.protocol == "https:" ? "wss" : "ws";
        let socket_address = `${protocol}://${window.location.host}/ws/`;

        window.triviamaze_socket = new WebSocket(socket_address);
        // Connection opened
        window.triviamaze_socket.addEventListener('open', function (_event) {
            const m = {
                id: window.triviamaze_session,
                cmd: {
                    type: "Join"
                }
            };
            let s = JSON.stringify(m)
            window.triviamaze_socket.send(s);
            window.setInterval(app.join.bind(app), 5000);
        });

        // Allow using keyboard arrows
        document.addEventListener("keydown", this.handleArrows.bind(this));

// Listen for messages
        window.triviamaze_socket.addEventListener('message', function (event) {
            let m =
                JSON.parse(event.data);
            if (m.type === "Scores") {
                m.scores.sort((a, b) => b.total_score - a.total_score);
            }
            app.setState({
                last_message: m,
            });

            if (window.location.hostname === "localhost") {
                console.log(m);
            }

            if (m.type === "GetGame") {
                const hash_game = window.location.hash.slice(1).trim().slice(-6);
                if (hash_game.length > 4) {
                    const m = {
                        id: window.triviamaze_session,
                        cmd: {
                            type: "SetGame",
                            game: hash_game
                        }
                    };

                    window.triviamaze_socket.send(JSON.stringify(m));
                    window.location = window.location.origin;

                }

            }


        });

        window.triviamaze_socket.addEventListener('close', function () {
            window.location.reload();
        });

        window.triviamaze_socket.addEventListener('error', function () {
            window.location.reload();
        });


    }


    handleChange(event) {
        let r = /[^a-z0-9A-Z ]/g;
        this.setState({
            [event.target.name]: event.target.value.replaceAll(r, "")
        })
    }

    handleChangeNoReplace(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSelectChange(event) {
        this.setState({
            difficulty: event.target.value
        })
    }


    handleQuestionChange(i, event) {
        let q = this.state.questions_array;
        let s = event.target.value;
        s = s.replaceAll("\n", "").replaceAll("|", "");
        q[i] = s;
        this.setState({
            questions_array: q
        });
    }

    handleAnswerChange(i, event) {
        let a = this.state.answers_array;
        let s = event.target.value;
        s = s.replaceAll("\n", "").replaceAll("|", "");
        a[i] = s;
        this.setState({
            answers_array: a
        });
    }

    addQuestion() {
        let a = this.state.answers_array;
        let q = this.state.questions_array;

        a.push("");
        q.push("");

        this.setState({
            answers_array: a,
            questions_array: q,
        });

    }

    deleteQuestion(i) {
        let a = this.state.answers_array;
        let q = this.state.questions_array;

        a.splice(i, 1);
        q.splice(i, 1);


        this.setState({
            answers_array: a,
            questions_array: q,
        });

    }

    getQuestionAnswers() {
        let a = this.state.answers_array;
        let q = this.state.questions_array;
        let v = [];
        v.push(<div className={"row"}>
            <h3>Or Create a New Game with Your Own Questions</h3>
        </div>);

        for (let i = 0; i < a.length; ++i) {
            v.push(
                <div>

                    <div className={"row"}>
                        <input onChange={this.handleQuestionChange.bind(this, i)} placeholder={`Question ${i + 1}`}
                               value={q[i]}/>
                    </div>
                    <div className={"row"}>
                        <input onChange={this.handleAnswerChange.bind(this, i)} placeholder={`Answer ${i + 1}`}
                               value={a[i]}/>
                    </div>
                    <div className={"row mb-3"}>
                        <button className={"btn btn-primary"} onClick={this.deleteQuestion.bind(this, i)}>Delete
                            Question
                        </button>
                    </div>


                </div>);
        }
        v.push(<div className={"row mb-2"}>
                <button className={"btn btn-primary"} onClick={this.addQuestion.bind(this)}>Add Question</button>
            </div>
        );

        if (a.length > 0) {

            v.push(<div className={"row"}>
                    <button className={"btn btn-primary"} onClick={this.newGameFromQuestionAnswers.bind(this)}>Create Game
                    </button>
                </div>
            );
        }

        return v;

    }


    get_player_entry() {
        return <div>
            <div className={"row"}>
                <h4>{`Your Player: ${this.state.last_message.player}`}</h4>
            </div>
            <label className={"form-label"}>
                Name
                <div className={"row"}>
                    <input className={"form-control"} type="text" name={"name"} value={this.state.name}
                           onChange={this.handleChange}/>
                </div>
                <div className={"row mb-5"}>
                    <button className={"btn btn-primary"} onClick={
                        this.setName.bind(this)}>Update Name
                    </button>
                </div>

            </label>


            <label className={"form-label"}>
                Team
                <div className={"row"}>
                    <input className={"form-control"} type="text" name={"team"} value={this.state.team}
                           onChange={this.handleChange}/>
                </div>
                <div className={"row"}>
                    <button className={"btn btn-primary"} onClick={
                        this.setTeam.bind(this)}>Update Team
                    </button>
                </div>

            </label>

            <label className={"form-label"}>
                Difficulty

                <div className={"row"}>
                    <select className={"form-select"} name={"difficulty"} value={this.state.difficulty}
                            onChange={this.handleChange}>
                        <option value="">Normal</option>
                        <option value="kid">Kid</option>
                        <option value="pro">Pro</option>
                    </select>
                </div>
                <div className={"row"}>
                    <button className={"btn btn-primary"} onClick={
                        this.setDifficulty.bind(this)}>Update Difficulty
                    </button>
                </div>

            </label>


        </div>

    }

    cell_type_to_color(type) {
        if (type === "Current") {
            return "yellow";
        }
        if (type === "Visited") {
            return "green";
        }
        if (type === "UnVisited") {
            return "gray";
        }


    }

    get_columns(grid, r) {
        let v = [];
        let row = grid[r];
        for (let c = 0; c < row.length; ++c) {
            let cell = row[c];
            let class_name = cell.type + " AnswerGrid ";
            if (r === Math.floor(grid.length / 2) && c === Math.floor(row.length / 2)) {
                class_name += " Center";
            }
            if (r + 1 < grid.length && grid[r + 1][c].type == "Current") {
                v.push(<td key={`${r},${c}`}
                           className={class_name} onClick={this.move_up.bind(this)}>{cell.letter} </td>);
            } else if (r - 1 >= 0 && grid[r - 1][c].type == "Current") {
                v.push(<td key={`${r},${c}`}
                           className={class_name} onClick={this.move_down.bind(this)}>{cell.letter}  </td>);
            }
            else if (c + 1 < row.length && row[c + 1].type == "Current") {
                v.push(<td key={`${r},${c}`}
                           className={class_name} onClick={this.move_left.bind(this)}>{cell.letter}  </td>);
            } else if (c - 1 >= 0 && row[c - 1].type == "Current") {
                v.push(<td key={`${r},${c}`}
                           className={class_name} onClick={this.move_right.bind(this)}> {cell.letter}
                </td>);
            } else {
                v.push(<td key={`${r},${c}`} className={class_name}>{cell.letter}</td>);
            }


        }
        return v;


    }

    get_score_table(msg) {
        msg.scores.sort((a, b) => {
            return b.total_score - a.total_score;
        })
        let rows = [];
        for (let i = 0; i < msg.scores.length; ++i) {
            let score = msg.scores[i];
            rows.push(<tr key={i}>
                <td>{score.player}</td>
                <td>{score.total_score}</td>
            </tr>);
        }
        return <table className={"table"}>
            <thead>
            <tr>
                <th>{"Player"}</th>
                <th>{`Score (in ${msg.total_duration.toFixed(1)} seconds)`}</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>;


    }

    get_question_score_table(msg) {
        let rows = [];
        msg.scores.sort((a, b) => {
            return b.question_score - a.question_score;
        })
        for (let i = 0; i < msg.scores.length; ++i) {
            let score = msg.scores[i];
            rows.push(<tr key={i}>
                <td>{score.player}</td>
                <td>{score.question_score}</td>
                <td>{score.total_score}</td>
            </tr>);
        }
        return <table className={"table"}>
            <thead>
            <tr>
                <th>{"Player"}</th>
                <th>{"Question Score"}</th>
                <th>{"Total Score"}</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>;


    }


    get_rows(grid) {
        let v = [];
        for (let r = 0; r < grid.length; ++r) {
            v.push(<tr key={r}>{this.get_columns(grid, r)}</tr>);
        }
        return v;
    }

    get_grid() {
        return <table>
            <tbody>

            {this.get_rows(this.state.last_message.grid.grid)}
            </tbody>

        </table>;
    }

    get_grid_buttons() {
        if (this.state.last_message.player.trim() !== "Observer") {

            return <div>
                <div className={"row"}>
                    <button className={"btn btn-primary col-4 offset-4"} onClick={this.move_up.bind(this)}>Up</button>
                </div>
                <div className={"row"}>
                    <button className={"btn btn-primary col-4"} onClick={this.move_left.bind(this)}>Left</button>
                    <button className={"btn btn-primary col-4 offset-4"} onClick={this.move_right.bind(this)}>Right
                    </button>
                </div>
                <div className={"row"}>
                    <button className={"btn btn-primary col-4 offset-4"} onClick={this.move_down.bind(this)}>Down
                    </button>
                </div>

            </div>;
        } else {
            return <div/>;
        }

    }

    make_invisible_if_not_secret() {
        if (this.state.game === "7777777") {
            return "visible";
        } else {
            return "invisible";
        }
    }

    is_tv() {
        return window.location.toString().search("tv") !== -1;
    }

    exclude_if_tv(fragment) {
        if (this.is_tv()) {
            return <></>;
        } else {
            return fragment;
        }
    }

    exclude_if_not_tv(fragment) {
        if (!this.is_tv()) {
            return <></>;
        } else {
            return fragment;
        }
    }

    get_qr_scanner() {
        if (this.state.show_qr_scanner) {
            return <div className={"row"}>
                <QrReader constraints={{facingMode: 'environment'}}
                          onResult={(result, error) => {
                              if (!!result) {
                                  console.log(result?.text);
                                  let url = new URL(result?.text);
                                  const hash_game = url.hash.slice(1).trim().slice(-6);
                                  if (hash_game.length > 4) {
                                      const m = {
                                          id: window.triviamaze_session,
                                          cmd: {
                                              type: "SetGame",
                                              game: hash_game
                                          }
                                      };

                                      window.triviamaze_socket.send(JSON.stringify(m));
                                  }
                              }

                              if (!!error) {
                                  console.info(error);
                              }
                          }}
                          style={{width: '100%'}}
                />
            </div>;
        } else {
            return <></>;
        }

    }

    render_last_message() {
        if (!this.state.last_message) {
            return <h1>Waiting for server</h1>
        } else {
            let type = this.state.last_message.type;
            let msg = this.state.last_message;
            if (type === "GetGame") {
                return <div>
                    {this.exclude_if_not_tv(
                        <div className={"row"}>
                            <h2>{`TVID: ${msg.tvid}`}</h2>
                        </div>)}
                    {this.exclude_if_tv(<>
                        <div className={"row"}>
                            <h3>Enter the 6 digit game code to join an existing game.</h3>
                            <input type={"number"} autoComplete={"off"} className={"col"} name={"game"}
                                   value={this.state.game} onChange={this.handleChange}/>
                        </div>
                        <div className={"row"}>
                            <button className={"col btn btn-primary"} onClick={this.set_game.bind(this)}>Join Game
                            </button>
                        </div>
                        {this.get_qr_scanner()}

                        <div className={"row mb-5"}>
                            <button className={"col btn btn-primary"} onClick={() => {
                                this.setState({show_qr_scanner: !this.state.show_qr_scanner})
                            }}>
                                {this.state.show_qr_scanner ? "Hide QR Scanner" : "Scan QR Code"}
                            </button>

                        </div>

                        <div className={"row"}>
                            <h3>Or Create a New Game with Built-In Questions</h3>
                        </div>
                        <div className={"row"}>
                            <button className={"btn btn-primary"}
                                    onClick={this.new_built_in_game.bind(this, "trivia")}>General Trivia Questions
                            </button>
                        </div>
                        <div className={"row"}>
                            <button className={"btn btn-primary"}
                                    onClick={this.new_built_in_game.bind(this, "capitals")}>Countries and Capitals
                            </button>
                        </div>
                        <div className={"row"}>
                            <button className={"btn btn-primary"}
                                    onClick={this.new_built_in_game.bind(this, "state capitals")}>US States and Capitals
                            </button>
                        </div>

                        <div className={"row mb-5"}>
                            <button className={"btn btn-primary"}
                                    onClick={this.new_built_in_game.bind(this, "bible")}>Bible
                                Trivia
                            </button>
                        </div>

                        <div>
                            {this.getQuestionAnswers()}
                        </div>

                        <div className={`row ${this.make_invisible_if_not_secret()}`}>
                            <h3>Or create a new game by entering the questions and answers for a new quiz.</h3>
                            <h4>(Put each question and answer on its own line</h4>
                            <textarea rows={10} name={"questions"} value={this.state.questions}
                                      onChange={this.handleChangeNoReplace}/>
                            <button className={"btn btn-primary"} onClick={this.new_game.bind(this)}>New Game</button>
                        </div>
                    </>)
                    }
                </div>
            } else if (type === "GetName") {
                return this.get_player_entry();
            } else if (type === "WaitingToStart") {
                return <div>
                    <h2>Waiting for the host to start the game</h2>
                    {this.get_player_entry()}
                    <h3>Players</h3>
                    <ul className={"list-group"}>
                        {msg.players.map((p) => <li className={"list-group-item"} key={p}>{p}</li>)}
                    </ul>

                </div>
            } else if (type === "GameCreated") {
                return <div>
                    <h2>Game Code: {msg.game} ({msg.num_questions} questions) </h2>
                    <h4>Go to <a href={`${window.location.origin}/#${msg.game}`} target={"_blank"}
                                 rel="noreferrer">{`${window.location.origin}/#${msg.game}`}</a></h4>
                    <h4>Or scan the QR Code below</h4>
                    <QrCode value={`${window.location.origin}/#${msg.game}`}/>
                    <div className={"mb-5"}/>
                    <h3>Players</h3>
                    <ol>
                        {msg.players.map((p) => <li key={p}>{p}</li>)}
                    </ol>
                    {this.exclude_if_tv(
                        <>
                            <button className={"btn btn-primary"} onClick={this.start_game.bind(this)}>Start Game
                            </button>
                            <div className={"row"}>
                                <h3>Enter the tvid to link a tv.</h3>
                                <input type={"number"} autoComplete={"off"} className={"col"} name={"tvid"}
                                       value={this.state.tvid} onChange={this.handleChange}/>

                                <button className={"btn btn-primary"} onClick={this.add_observer.bind(this)}>Link TV
                                </button>
                            </div>
                            <div className={"mb-5"}/>
                        </>)
                    }

                </div>

            } else if (type === "ShowQuestionGrid") {
                return <div>
                    <div className={"row"}>
                        <h3>{`${this.state.last_message.player}: ${this.state.last_message.score}`}</h3>
                    </div>

                    <div className={"row"}>
                        <h3>{`Question ${this.state.last_message.grid.current + 1} of ${this.state.last_message.grid.questions}:`}</h3>
                    </div>

                    <div className={"row"}>
                        <h4>{this.state.last_message.grid.question}</h4>
                    </div>
                    <div className={"row"}>{this.get_grid()}</div>
                    <div>{this.get_grid_buttons()}</div>
                </div>

            } else if (type === "Penalty") {

                const renderer = ({hours, minutes, seconds, milliseconds, completed}) => {
                    if (completed) {
                        // Render a complete state
                        return < ></>;
                    } else {
                        // Render a countdown
                        return (

                            <h3>{`Penalty for  ${seconds}.${milliseconds / 100} seconds`}</h3>
                        );
                    }
                };
                return <div>
                    <h2>Incorrect</h2>
                    <Countdown date={Date.now() + msg.seconds * 1000} renderer={renderer} intervalDelay={50}
                               precision={1}/>
                </div>
            } else if (type === "Score") {
                let v = [];
                if (msg.complete) {
                    if (msg.player.length > 0) {

                        v.push(
                            <h2>{`Game Over:`}</h2>);
                        v.push(<h3>{`Total Questions: ${msg.questions}`}</h3>);
                        v.push(<h3>{`Your score: ${msg.score} points `}</h3>);
                    }
                    v.push(
                        <h2>{`Final Scores`}</h2>
                    );
                } else {
                    const renderer = ({hours, minutes, seconds, milliseconds, completed}) => {
                        // Render a countdown
                        return (
                            <h2>{`The next question will start in ${seconds} seconds`}</h2>
                        );
                    };
                    if (msg.player.length > 0) {
                        v.push(
                            <h2>{`You have answered ${msg.current} of ${msg.questions} with a score of ${msg.score}`}</h2>);
                        if (msg.auto_advance_question) {
                            v.push(
                                <Countdown date={Date.now() + msg.next_question_in * 1000} renderer={renderer}
                                           intervalDelay={50} precision={1}/>);
                        } else {
                            v.push(
                                <h2>{"The next question will start after the host advances to the next question."}</h2>);
                        }
                    } else {
                        v.push(<h2>{`On question ${msg.current + 1} of ${msg.questions}`}</h2>);
                    }
                }

                v.push(this.get_score_table(msg));
                if (msg.complete) {
                    v.push(
                        <button className={"btn btn-primary"}
                                onClick={this.reset_session.bind(this)}>
                            New Game
                        </button>
                    );
                }
                return <div>{v}</div>
            } else if (type === "OnQuestionCoordinator") {
                return <div>
                    <div className={"row"}>
                        <h3>{`Question ${this.state.last_message.grid.current + 1} of ${this.state.last_message.grid.questions}:`}</h3>
                    </div>

                    <div className={"row"}>
                        <h4>{this.state.last_message.grid.question}</h4>
                    </div>
                    <div className={"row"}>{this.get_grid()}</div>
                    <div className={"row"}>
                        {this.get_question_score_table(this.state.last_message)}
                    </div>
                </div>

            } else if (type === "AnsweredQuestion") {
                let v = [];
                v.push(<h2>{`Question: ${msg.question}`}</h2>)
                if (msg.player.length > 0) {
                    v.push(
                        <h2>{`${msg.winner} got the answer ${msg.answer} first in ${msg.question_duration.toFixed(1)} seconds.\nYou have ${msg.score} points so far through ${msg.current} of ${msg.questions} questions.`}</h2>
                    );
                } else {
                    v.push(
                        <h2>{`${msg.winner} got the answer ${msg.answer} first in ${msg.question_duration.toFixed(1)} seconds.`}</h2>
                    );
                    v.push(
                        <h2>{`Scores so far through ${msg.current} of ${msg.questions} questions.`}</h2>
                    );


                }
                if (msg.current !== msg.questions) {
                    if (msg.auto_advance_question) {
                        const renderer = ({hours, minutes, seconds, milliseconds}) => {
                            // Render a countdown
                            return (
                                <h2>{`The next question will start in ${seconds} seconds`}</h2>
                            );
                        };

                        v.push(
                            <Countdown date={Date.now() + msg.next_question_in * 1000} renderer={renderer}
                                       intervalDelay={50} precision={1}/>);
                    } else {
                        v.push(<h2>{"The next question will start after the host advances to the next question."}</h2>);
                    }

                }
                if (msg.player.length === 0 && msg.auto_advance_question === false && !this.is_tv()) {
                    v.push(<button className={"btn btn-primary"} onClick={this.next_question.bind(this)}>Next
                        Question</button>);
                }

                v.push(this.get_score_table(msg));
                return <div>{v}</div>
            }
        }
    }

    handleArrows(e) {
        console.log(e.code);
        if (e.code === "ArrowUp") {
            this.move_up();
            e.preventDefault();
        } else if (e.code === "ArrowDown") {
            this.move_down();
            e.preventDefault();
        } else if (e.code === "ArrowLeft") {
            this.move_left();
            e.preventDefault();
        } else if (e.code === "ArrowRight") {
            this.move_right();
            e.preventDefault();
        }


    }

    render() {
        return (
            <div className="App container">
                {
                    this.render_last_message()
                }
            </div>
        );
    }

}

export default App;
